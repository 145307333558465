import {reactive, toRefs} from "vue";
import service from "@/utils/axios";
import {useRouter} from "vue-router";
import {onPageLoadingEnd, onPageLoadingStart} from "@/utils/DLoading";
import moment from "moment";

export interface IClassItem {
    "className": string;
    "classId": string;
    "scheduleId": string;
    "classRoomName": string;
    "startTime": string;
    "endTime": string;
    "haveEnd": boolean;
    "week": number;
    "beginDate": string;
    "subjectName": string;
}

export interface IClassList {
    courseList: Array<IClassItem>;
    form: Object,
    option1:Array<any>
    option2:Array<any>
}

export function classListBill() {

    let stateRef = reactive(<IClassList>{
        courseList: [],
        form: {
            year: parseInt(moment().format('YYYY')),
            semesterId: '1',
        },
        option1:[],
        option2:[],
    })
    const filters = (v) => {
        switch (v){
            case 1:
                return '星期一'
            case 2:
                return '星期二'
            case 3:
                return '星期三'
            case 4:
                return '星期四'
            case 5:
                return '星期五'
            case 6:
                return '星期六'
            case 7:
                return '星期天'
        }
    }
    const opt1 = () => {
        let _monthNow = moment().format('M')
        let _yearNow = parseInt(moment().format('YYYY'));
        let list = [];
        if (_monthNow === '12') {
            for (let i = 0; i < 6; i++) {
                // @ts-ignore
                list.push({text: (_yearNow - 4 + i).toString(), value: _yearNow - 4 + i})
            }
        } else {
            for (let i = 0; i < 6; i++) {
                // @ts-ignore
                list.push({text: (_yearNow - 5 + i).toString(), value: _yearNow - 5 + i})
            }
        }
        stateRef.option1 = list
    }
    const router = useRouter();
    let refData = toRefs(stateRef);

    const init = async () => {
        await getCourseList();
        await opt1()
        await gTermList()
    }

    const getCourseList = async () => {
        onPageLoadingStart()
        stateRef.courseList = await service.post(`/dwart/an_teaching/hbk/v1/queryCourseByUid`,{...stateRef.form})
        onPageLoadingEnd()
    }

    const gTermList = async () => {
        stateRef.option2 = await service.get('/dwart/an_teaching/hbk/v1/semesterList',);
        // let _op = _res.map(v => {
        //     return {text: v.semesterName, value: v.id}
        // });
        // this.option2 = this.option2.concat(_op);
    }

    const onClick = async (item: IClassItem) => {
        await router.push({
            name: 'ReportClass',
            query: {
                name: item.className,
                classId: item.classId,
                report: 1,
                subjectName: item.subjectName,
            }
        })
    }

    return {
        refData,
        init,
        onClick,
        getCourseList,
        filters,
    }
}
